<template>
  <tr class="is-centered">
    <td>{{ transaction.transactionId }}</td>
    <td>{{ transaction.email }}</td>
    <td>{{ dateToCET(transaction.timestamp) }}</td>
    <td>{{ formatAmount(transaction.settledAmount, 'JPY', transaction.creation) }}</td>
    <td
      ref="targetFirstName"
      @mouseover="translateOnHover(transaction.firstName, targetFirstName)"
    >
      {{ transaction.firstName }}
    </td>
    <td
      ref="targetSurname"
      @mouseover="translateOnHover(transaction.surname, targetSurname)"
    >
      {{ transaction.surname }}
    </td>
    <td>{{ transaction.senderName }}</td>
    <td>{{ transaction.bankName }}</td>
    <td>{{ transaction.bankCode }}</td>
    <td>{{ transaction.branchCode }}</td>
    <td>{{ transaction.accountNumber }}</td>
    <td>{{ transaction.companyName }}</td>
    <td>{{ transaction.nonce }}</td>
    <td>{{ transaction.merchantId }}</td>
    <td
      v-if="[
        'CREATED', 'PRECREATED', 'INITIALIZED', 'BLOCKED'
      ].includes(transaction.status)"
    />
    <td v-else>
      {{ dateToCET(transaction.transactionRequestDate) }}
    </td>
    <td>
      <StatusIcon
        :status="transaction.status"
      />
    </td>
    <td v-if="shouldDisplayCreation">
      <span
        class="p-1"
        :class="classNameForCreation(transaction.creation)"
      >
        {{ transaction.creation }}
      </span>
    </td>
    <td>
      <span
        v-if="transaction.userAgent !== '' && !copiedAgent"
        ref="targetUserAgent"
        class="copy-data"
        @click="copyAgent(transaction.userAgent)"
        @mouseover="userDataTooltip(transaction.userAgent, targetUserAgent)"
      >
        <i
          class="far fa-copy"
        />
        {{ transaction.userAgent.substring(0, 6) }}
      </span>
      <span
        v-else-if="copiedAgent"
      >
        Copied
      </span>
    </td>
    <td>
      <span
        v-if="
          transaction.fingerprint === '' && !copiedFingerprint
            && transaction.userAgent.length === 32
        "
        ref="targetUserAgent"
        class="copy-data"
        @click="copyFingerprint(transaction.fingerprint)"
        @mouseover="userDataTooltip(transaction.fingerprint, targetUserAgent)"
      >
        <i
          class="far fa-copy"
        />
        {{ transaction.userAgent.substring(0, 6) }}
      </span>
      <span
        v-else-if="transaction.fingerprint !== '' && !copiedFingerprint"
        ref="targetUserAgent"
        class="copy-data"
        @click="copyFingerprint(transaction.fingerprint)"
        @mouseover="userDataTooltip(transaction.fingerprint, targetUserAgent)"
      >
        <i
          class="far fa-copy"
        />
        {{ transaction.fingerprint.substring(0, 6) }}
      </span>
      <span
        v-else-if="copiedFingerprint"
      >
        Copied
      </span>
    </td>
    <td>
      <span
        v-if="transaction.ipAddress !== '' && !copiedIp"
        ref="targetIp"
        class="copy-data"
        @click="copyIp(transaction.ipAddress)"
        @mouseover="userDataTooltip(transaction.ipAddress, targetIp)"
      >
        <i
          class="far fa-copy"
        />
        {{ displayIpAddress(transaction.ipAddress) }}
      </span>
      <span
        v-else-if="copiedIp"
      >
        Copied
      </span>
    </td>
    <td>{{ formatAmount(transaction.processingFee, transaction.currency, 'SYSTEM') }}</td>
    <td>{{ formatTransactionFee(transaction.transactionFee, transaction.currency, 'SYSTEM') }}</td>
    <td>
      {{ formatAmount(transaction.authorizedAmount, transaction.currency, transaction.creation) }}
    </td>
    <td v-if="shouldDisplaySourceBankName">
      {{ transaction.sourceBankName }}
    </td>
    <td>
      <button
        v-if="transaction.creation !== 'MANUAL' && transaction.status !== 'BLOCKED'"
        class="is-clickable button is-small is-primary modal-button"
        @click="edit(transaction)"
      >
        <i class="fas fa-lg fa-pencil-alt" />
      </button>
    </td>
    <td>
      <ReceiveTransaction
        v-if="transaction.status === 'CREATED'"
        :transaction-id="transaction.transactionId"
      />
    </td>
    <td v-if="shouldDisplayReceivedBy">
      {{ transaction.receivedBy }}
    </td>
  </tr>
</template>

<script>
import { inject, ref } from 'vue';
import { useTippy } from 'vue-tippy';
import ReceiveTransaction from './ReceiveTransaction.vue';
import StatusIcon from './StatusIcon.vue';
import initializeFlagsmith from '../flagsmith';
import 'tippy.js/dist/tippy.css';

export default {
    components: {
        StatusIcon,
        ReceiveTransaction,
    },

    props: {
        transaction: {
            type: Object,
            required: true,
        },
        edit: {
            type: Function,
            required: true,
        },
    },

    async setup() {
        const {
            transactions: {
                translate,
            },
        } = inject('btb_transactions');

        const targetFirstName = ref(null);
        const targetSurname = ref(null);
        const targetUserAgent = ref(null);
        const targetIp = ref(null);
        const copiedIp = ref(false);
        const copiedAgent = ref(false);
        const copiedFingerprint = ref(false);

        const translateName = async (name) => {
            try {
                const response = await translate(name);
                return response.text;
            } catch (error) {
                console.error(error, error.stack);
            }
            return null;
        };

        const translateOnHover = (name, target) => {
            translateName(name).then((result) => {
                // eslint-disable-next-line no-underscore-dangle
                if (!target._tippy) {
                    useTippy(target, {
                        content: result,
                    });
                    // eslint-disable-next-line no-underscore-dangle
                    target._tippy.show();
                }
            });
        };

        const formatAmount = (amount, curr, creation) => {
            if (creation === 'REFUND' || creation === 'TRANSFER BACK') {
                return new Intl.NumberFormat('en-US', { style: 'currency', currency: curr }).format((amount / 100) * -1);
            }

            return new Intl.NumberFormat('en-US', { style: 'currency', currency: curr }).format(amount / 100);
        };

        const formatTransactionFee = (amount, curr, creation) => {
            if (amount === '') {
                return '';
            }

            return formatAmount(amount, curr, creation);
        };

        const dateToCET = (date) => `${date.toLocaleString('en-GB', {
            timeZone: 'CET',
            weekday: 'short',
            year: 'numeric',
            month: 'short',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
            second: 'numeric',
        })} CET`;

        const classNameForCreation = (value) => {
            const values = {
                MODIFIED: 'has-background-warning',
                MANUAL: 'has-background-danger has-text-white',
                REFUND: 'has-background-info has-text-white',
                'TRANSFER BACK': 'has-text-info',
                AUTO: 'has-background-black has-text-white',
                SYSTEM: 'has-text-danger-dark',
            };

            return values[value] || 'has-text-black-bis';
        };

        const flagsmith = await initializeFlagsmith();
        const shouldDisplaySourceBankName = flagsmith.hasFeature('btb_source_bank');
        const shouldDisplayReceivedBy = flagsmith.hasFeature('btb_admin_received_by_column');
        const shouldDisplayCreation = flagsmith.hasFeature('creation_status_btb');

        const displayIpAddress = (ipAddress) => {
            if (ipAddress === '') {
                return '';
            }

            const ipParts = ipAddress.split('.');
            return `${ipParts[0]}.${ipParts[1]}`;
        };

        const copyIp = async (text) => {
            await navigator.clipboard.writeText(text);
            copiedIp.value = true;
            setTimeout(() => { copiedIp.value = false; }, 1000);
        };

        const copyFingerprint = async (text) => {
            await navigator.clipboard.writeText(text);
            copiedFingerprint.value = true;
            setTimeout(() => { copiedFingerprint.value = false; }, 1000);
        };

        const copyAgent = async (text) => {
            await navigator.clipboard.writeText(text);
            copiedAgent.value = true;
            setTimeout(() => { copiedAgent.value = false; }, 1000);
        };

        const userDataTooltip = (text, target) => {
            // eslint-disable-next-line no-underscore-dangle
            if (!target._tippy) {
                useTippy(target, {
                    content: text,
                });
                // eslint-disable-next-line no-underscore-dangle
                target._tippy.show();
            }
        };

        return {
            formatAmount,
            formatTransactionFee,
            dateToCET,
            shouldDisplaySourceBankName,
            shouldDisplayReceivedBy,
            classNameForCreation,
            shouldDisplayCreation,
            targetFirstName,
            targetSurname,
            targetUserAgent,
            targetIp,
            translate,
            translateName,
            translateOnHover,
            displayIpAddress,
            copyIp,
            copyAgent,
            copyFingerprint,
            userDataTooltip,
            copiedIp,
            copiedAgent,
            copiedFingerprint,
        };
    },
};
</script>

<style>
.is-not-wrapped td {
  white-space: nowrap;
}
</style>
